footer {
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    padding: 5% 0% 5% 0%;
    box-shadow: 0px 2.5px 5px #9E9E9E;
    text-align: center;
    gap: 10px;
}

.footer-text p {
    color: gray;
    text-align: center;
    font-size: 16px;
    margin: 0;
}

#disclaimer {
    font-size: 12px;
    font-style: italic;
    margin-top: 3px;
    margin-bottom: 15px;
}

.footer-links {
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 15px;
}

.github {
    border: 1.5px solid black;
    border-radius: 5%;
    box-shadow: 1.5px 1.5px 2px #9E9E9E;
    padding: 6px 8px 4px 10px;
}

.youtube {
    border: 1.5px solid black;
    border-radius: 5%;
    box-shadow: 1.5px 1.5px 2px #9E9E9E;
    padding: 9px 8px 0px 8px;
}

.github:hover { background-color: lightgray; }
.github:active { background-color: darkgray; }
.github-pic-pic {height: 23px;}
.github-logo-pic {height: 20px;}

.youtube:hover { background-color: pink; }
.youtube:active { background-color: lightpink; }
.youtube-pic {height: 18px;}

@media (max-width: 600px) {
    .github-pic-pic { height: 18px }
    .github-logo-pic { height: 16px }
    .youtube-pic { height: 14px }
    .youtube { padding: 7.7px 8px 0px 8px;}
    .footer-text p { font-size: 12px }
    #disclaimer { font-size: 8px }
}