.card {
    border: 1.5px solid gray;
    width: 13vw;
    height: 13vw;
    box-shadow: 3px 3px 1vw #9E9E9E;
    padding: 2vw 2vw 1vw 2vw;
    font-size: 1.1vw;
    border-radius: 10%;
}

.card:hover {
    box-shadow: 5px 5px 1.5vw #1d1d1d;
    cursor: pointer;
}

.card:active {
    box-shadow: 2px 2px 1vw #9E9E9E;
}

img {
    height: 10vw;
}

@media (max-width: 600px) {
    .card {
        width: 104px;
        height: 104px;
        font-size: 8px;
        border-width: 1px;
    }

    .card:hover {
        box-shadow: 2px 2px 1.5vw #1d1d1d;
    }

    .card:active {
        box-shadow: 1.5px 1.5px 1vw #9E9E9E;
    }

    img {
        height: 80px;
    }
}