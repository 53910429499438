.expContainer h3 {
	font-size: 36px;
}

.expContainer h4 {
	margin: 0;
	padding: 0;
	font-size: 1.2vw;
	font-weight: normal;
}
	
.expContainer h5 {
	margin: 0;
	padding: 0;
	font-size: 18px;
}

.expContainer .school {
	text-transform: uppercase;
	font-size: 24px;
	margin-bottom: 8px;
}

.eduContainer {
	display: grid;
	align-items: center;
	grid-template-columns: 50% 50%;
	padding-bottom: 10%;
	row-gap: 2vw;
}

.eduContainer img {
	display: block;
    margin: 0 auto;
	width: 75%;
	height: auto;
	border-radius: 50px;
}

.workContainer {
	display: flex;
	padding-bottom: 10%;
}

.workContainer #employer { flex: 1; }
.workContainer #position { flex: 1; }
.workContainer #date { flex: 1; }

.skillsContainer {
	display: flex;
	padding-bottom: 10%;
}

.skillsContainer #category { flex: 1; }
.skillsContainer #skill { flex: 3; font-weight: normal }


@media (max-width: 784px) {
	.eduContainer { display: block; }

	.eduContainer img { 
		margin-top: 6vh;
		max-width: 300px;
	}

	.expContainer .school { font-size: 18px; }

	.expContainer h3 { 
		text-align: center;
		font-size: 28px;
	}
		
	.expContainer h4 { font-size: 9px; }
	.expContainer h5 { font-size: 12px; }
	.expContainer li { font-size: 10px; }
	.expContainer p { font-size: 10px; }
}