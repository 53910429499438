#main {
	margin-left: 10%;
	margin-right: 10%;
	min-height: calc(100vh - 300px);
}

body {
	font-family: 'Roboto', sans-serif;
}

/* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
/* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */

h1 {
	font-size: 5.5vw;
	font-family: 'Courier New', Courier, monospace;
	text-align: center;
	margin-top: 8vh;
	margin-bottom: 7vh;
}

h2 {
	font-size: 2.5vw;
	font-family: 'Courier New', Courier, monospace;
}

h3 {
	font-size: 24px;
}

h5 {
	font-weight: normal;
}

p {
	text-align: left;
}

@media (max-width: 784px) {
	#main {
		margin-left: 5%;
		margin-right: 5%;
	}

	h1 {
		font-size: 11vw;
	}

	h2 {
		font-size: 5vw;
	}
}