.project-container {
	margin: 5%;
}

.project-container h2 {
    text-align: center;
    font-size: 4vw;
}

.project-container iframe {
    flex: 1;
    width: 32vw;
    height: 18vw;
}

.project-container img {
    flex: 1;
    width: 32vw;
    height: auto;
}

.row {
    display: flex;
    gap: 3vw;
    align-items: center;
    margin-top: 3vw;
    margin-bottom: 6vw;
}

.row h3 {
    margin-bottom: -6px;
}

.tech-skills {
    margin-bottom: 40px;
}

.project-container p {
    flex: 1;
}

@media (max-width: 784px) {
    .project-container h2 {
        font-size: 36px;
    }

    .project-container h3 {
        font-size: 20px;
    }
    
    .project-container iframe {
        width: 72vw;
        height: 40.5vw;
        margin-top: 20px;
    }
    
    .project-container img {
        width: 100%;
        height: auto;
        margin-top: 20px;
    }

    .row {
        display: block;
        margin-bottom: 50px;
    }

    .tech-skills {
        margin-bottom: 20px;
    }
}