nav {
    display: flex;
    box-shadow: 0px 2.5px 10px #9E9E9E;
    padding: 15px 15px 10px 15px;
    font-family: "system-ui";
    text-transform: uppercase;
}

.nav-button {
    color: #2196f3;
    text-decoration: none;
    padding: 10px 15px 10px 10px;
    font-size: 16px;
}

.nav-button:hover {
    color: #4dabf5;
}

.nav-button:active {
    color: #1769aa;
}

@media (max-width: 600px) {
    nav {
        flex-direction: column;
    }

    .nav-button {
        padding: 10px 0px 10px 0px;
        text-align: center;
    }
}